<template>
  <div id="main">
    <template v-if="loggedIn">
      <Navbar></Navbar>
    </template>

    <router-view></router-view>

    <template v-if="loggedIn">
      <Foot></Foot>
    </template>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Navbar from "@/components/Navbar";
import Foot from "@/components/Foot";
import "@/mybulma.scss"

export default {
  name: "Principal",

  computed: {
    ...mapGetters({ loggedIn: "loggedIn" })
  },
  created() {
  },
  updated() {
  },
  methods: {
  },
  components: {
    Navbar,
    Foot
  }
};
</script>

