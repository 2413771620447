<template>
  <section class="hero is-bold app-navbar animated slideInDown">
    <div class="hero-head">
      <nav class="navbar is-white is-fixed">
        <div class="container">
          <div class="navbar-brand">
            <router-link to="/home" class="navbar-item brand-text menu-entry has-text-weight-bold">Espace perso</router-link>
            <img class="logo m-r-10" src="../assets/logo.png" />
            <div
              class="navbar-burger burger orange"
              @click="showNav = !showNav"
              :class="{ 'is-active': showNav }"
            >
              <span></span>
              <span></span>
              <span></span>
            </div>
          </div>
          <div class="navbar-menu is-hidden-desktop" :class="{ 'is-active': showNav }">
            <div class="navbar-end">
              <ul class="menu-list">
                <li>
                  <router-link to="/home" v-on:click.native="showNav = false">
                    <span class="icon is-small">
                      <i class="fa fa-home"></i>
                    </span>
                    <span class="menu-entry">
                      Tableau de bord
                    </span>
                  </router-link>
                  <router-link to="/infos" v-on:click.native="showNav = false">
                    <span class="icon is-small">
                      <i class="fa fa-user"></i>
                    </span>
                    <span class="menu-entry">
                      Mon profil
                    </span>
                  </router-link>
                   <router-link to="/services" v-on:click.native="showNav = false">
                    <span class="menu-entry">
                      Mes services
                    </span>
                  </router-link>
                  <router-link to="/inscription" v-on:click.native="showNav = false">
                    <span class="menu-entry">
                      Inscription
                    </span>
                  </router-link>
                  <router-link to="/exchange" v-on:click.native="showNav = false">
                    <span class="menu-entry">
                      Echanger un service
                    </span>
                  </router-link>
                  <router-link to="/analytics" class="invisible" v-on:click.native="showNav = !showNav">
                    <span class="menu-entry">
                      Statistiques
                    </span>
                  </router-link>
                  <router-link to="/calendrier" v-on:click.native="showNav = !showNav">
                    <span class="menu-entry">
                      Calendrier
                    </span>
                  </router-link>

                  <a v-if="mesInfos.status !== 'unpayed' && mesInfos.status !== 'not_concerned' && mesInfos.status !== 'blocked' && mesInfos.status !== 'unsubscribed'"
                  :href="`https://rayonsfantomes.net/?email=${mesInfos.email}&coop=${mesInfos.number}&FirstName=${mesInfos.name.split(',')[1]}&LastName=${mesInfos.name.split(',')[0]}`" target="_blank">
                    <span class="menu-entry">
                      Rayons Fantômes
                    </span>
                  </a>
                  <router-link to="/coordos" v-on:click.native="showNav = false">
                    <span class="menu-entry">
                      Coordinateurs
                    </span>
                  </router-link>

                  <router-link to="/badge" v-on:click.native="showNav = false">
                    <span class="menu-entry">
                      Mon badge
                    </span>
                  </router-link>

                  <a href="https://membres.v2.cooplalouve.fr/forum/" target="_blank">
                    <span class="menu-entry">
                      Forum
                    </span>
                  </a>

                  <a href="https://membres.cooplalouve.fr/" target="_blank">
                    <span class="icon is-small">
                      <img class="logo m-r-10" src="../assets/logo.png" />
                    </span>
                    <span class="menu-entry orange">
                      Espace membres
                    </span>
                  </a>
                  <router-link to="/logout">
                    <span class="icon is-small">
                      <i class="fa fa-sign-out"></i>
                    </span>
                    <span class="menu-entry">
                      Quitter
                    </span>
                  </router-link>
                </li>
              </ul>
            </div>
          </div>
          <div id="navMenu" class="navbar-menu">
            <div class="navbar-start">
              <router-link to="/home" class="navbar-item menu-entry">Tableau de bord</router-link>
              <a class="navbar-item dropdown menu-entry">
                Services&nbsp;
                <span class="icon is-small p-l-10">
                  <i class="fa fa-chevron-down"></i>
                </span>
                <div class="dropdown-content menu-entry">
                  <router-link to="/inscription">Inscription</router-link>
                  <router-link to="/services">Mes services</router-link>
                  <router-link to="/exchange">Echanger un service</router-link>
                </div>
              </a>
              <a class="navbar-item dropdown menu-entry">
                Communication&nbsp;
                <span class="icon is-small p-l-10">
                  <i class="fa fa-chevron-down"></i>
                </span>
                <div class="dropdown-content menu-entry">
                  <a href="https://membres.v2.cooplalouve.fr/forum/" target="_blank">Forum</a>
                  <router-link to="/coordos">Coordinateurs</router-link>
                </div>
              </a>
                  <router-link to="/analytics" class="navbar-item menu-entry invisible">Statistiques</router-link>
                  <router-link to="/calendrier" class="navbar-item menu-entry">Calendrier</router-link>
                  <a  v-if="mesInfos.status !== 'unpayed' && mesInfos.status !== 'not_concerned' && mesInfos.status !== 'blocked' && mesInfos.status !== 'unsubscribed'"
                  :href="`https://rayonsfantomes.net/?email=${mesInfos.email}&coop=${mesInfos.number}&FirstName=${mesInfos.name.split(',')[1]}&LastName=${mesInfos.name.split(',')[0]}`" class="navbar-item menu-entry" target="_blank">Rayons Fantômes</a>
                  <a href="https://membres.cooplalouve.fr/" class="navbar-item menu-entry orange" target="_blank">Espace membres</a>
            </div>
            <div class="navbar-end dropdown">
              <span class="navbar-item  menu-entry">
                <!-- tester si alerte-->
                <span class="icon is-small" v-if="mesInfos.status != 'up_to_date' && mesInfos.status != 'exempted' ">
                  <i class="fa fa-bell fa-2 red blink"></i>
                </span>
                <!-- -->
                <span class="login-name">{{ mesInfos.name }}</span>
                <img id="maPhoto" :src="image" @error="setDefaultImage" class="photo" alt="Ma photo">
              </span>
              <div class="dropdown-content  menu-entry">
                <router-link to="infos">Mon profil</router-link>
                <router-link to="/badge">Mon badge</router-link>
                <router-link to="/admin" v-if="admin">Administration</router-link>
                <router-link to="/logout">Se déconnecter</router-link>
              </div>
            </div>
          </div>
        </div>
      </nav>
    </div>
  </section>
</template>

<script>
export default {
  name: "Navbar",
  data: function () {
    return {
      showNav: false,
      mesInfos: { name: "" },
      admin: false,
      image: require("../assets/user.jpg"),
    };
  },
  mounted() {
    this.mesInfos = this.$store.getters.getUser;
    this.admin = this.$store.getters.isAdmin;
    this.image = "api/members/" + this.mesInfos.id + "/image";
  },
  computed: {},
  methods: {
    setDefaultImage: function () {
      this.image = require("../assets/user.jpg");
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~bulma/sass/utilities/mixins.sass";
.navbar-start {
  margin-left: 10px;
}

.navbar-item img {
  max-height: initial !important;
}

.slideInDown {
  animation-name: slideInDown;
}

.animated {
  animation-duration: 0.377s;
  animation-fill-mode: both;
}

.hero-head,
.navbar {
  height: 100%;
}

.app-navbar {
  position: fixed;
  min-width: 100%;
  z-index: 1024;
  box-shadow: 0 1px 0 rgba(0, 0, 0, 0.1);

  @include desktop {
    height: 70px;
  }
}

.hero {
  -ms-flex-align: stretch;
  align-items: stretch;
  background-color: #fff;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.logo {
  max-height: 35px;
  margin: auto;
}

.logo:hover {
  animation: rotate 1s ease-in-out forwards;
}

.menu-entry {
  font-weight: 550;
  font-size: 15px;
  color: hsl(0, 0%, 29%) !important;
}

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(-10deg) translate(5px, -5px) scale(1.1) skewX(-6deg);
  }
}

.dropdown-content {
  visibility: hidden;
  opacity: 0;
  transition: visibility 0s, opacity 0.5s linear;
  position: absolute;
  top: 52px;
  left: 0px;
  min-width: 300px;
  border-top: 3px solid #ff4200;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  z-index: 1;
  margin-top: 16px;
}

.dropdown-content a {
  color: black;
  padding: 5px 5px;
  text-decoration: none;
  display: block;
}

.dropdown-content a:hover {
  background-color: #ddd;
}

.dropdown:hover .dropdown-content {
  visibility: visible;
  opacity: 1;
}
.red {
  color: red;
}
.blink {
  animation: blink-animation 1s steps(5, start) infinite;
  -webkit-animation: blink-animation 1s steps(5, start) infinite;
}
@keyframes blink-animation {
  to {
    visibility: hidden;
  }
}
@-webkit-keyframes blink-animation {
  to {
    visibility: hidden;
  }
}

.photo {
  object-fit: cover;
  border-radius: 50%;
  height: 50px;
  width: 50px;
}

.login-name {
  min-width: 200px;
  text-align: right;
  padding-right: 15px;
}

.fa-chevron-down {
  color: #616161;
  font-size: 0.8em;
}

.invisible {
  display: none !important;
}

.orange {
  color: #ff4200 !important;
}
</style>
