<template>
  <footer>
    <br><br><br><br><br><br><br>
  </footer>
</template>

<script>
export default {
  name: "Foot"
};
</script>

<style>
footer {
	background: white;
}
</style>
